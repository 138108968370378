<template>
  <van-nav-bar title="开奖历史" left-arrow @click="back()" />
  <div class="moreHistory-page">
    <div class="page-content">
      <div class="select-box">
        <van-field v-model="lotteryType" is-link readonly label="彩种" placeholder="选择彩种" @click="showlotteryTypePopup = true" />
        <van-popup v-model:show="showlotteryTypePopup" destroy-on-close round position="bottom">
          <van-picker :model-value="lotteryTypeValue" :columns="columns" @cancel="showlotteryTypePopup = false" @confirm="onLotteryTypeConfirm" />
        </van-popup>
        <van-field v-model="workingDate" label="日期" placeholder="选择日期" is-link readonly @click="showCalendar = true" />
        <van-calendar v-model:show="showCalendar" @confirm="onDateConfirm" :min-date="minDate" :show-confirm="false" />
      </div>
      <div class="select-btm">
        <van-button :loading="selectBtm" type="primary" loading-text="查询中..." @click="submit">刷新</van-button>
      </div>
      <div class="table-pc" v-if="showPC28Table">
        <div class="table-header">
          <van-row class="row-header">
            <van-col class="item-col" span="6">期号</van-col>
            <van-col class="item-col" span="6">时间</van-col>
            <van-col class="item-col" span="6">开奖号码</van-col>
            <van-col class="item-col" span="6">总和</van-col>
          </van-row>
        </div>
        <div class="table-content">
          <van-row class="row-item" v-for="(item, index) of gameHistoryList" :key="index">
            <van-col class="item-col" span="6">{{ item.RoundNo }}</van-col>
            <van-col class="item-col" span="6">{{ item.DrawTS.split("T")[1] }}</van-col>
            <van-col class="item-col" span="6">
              <span v-for="(num, i) of formatPC28Num(item.DrawResult)" :key="i">{{ num }}</span>
            </van-col>
            <van-col class="item-col" span="6">{{ numberSum(item.DrawResult) }}</van-col>
          </van-row>
        </div>
      </div>
      <div class="table-tz" v-if="showTzTable">
        <div class="row" v-for="(item, index) of gameHistoryList" :key="index">
          <div class="row-header">
            <div class="header-roundNo">
              <span>第</span>
              <p>{{ item.RoundNo }}</p>
              <span>期开奖结果</span>
            </div>
            <div class="header-dateTime">{{ $moment(item.DrawTS).format("YYYY/MM/DD HH:mm:ss") }}</div>
          </div>
          <div class="row-content">
            <div class="item" v-for="(item, i) of gameNumList[index]" :key="i">
              <div :class="[`ball ball-${item.color}`]">
                <div class="ball-number">{{ item.number }}</div>
              </div>
              <div class="ball-class">{{ item.zodiac }}/{{ item.fiveElements }}</div>
            </div>
            <div class="item">
              <div class="ball">
                <div class="icon-add">+</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { getTodateLotteryDarw } from "@/api";
import { showToast } from "vant";
import numData2024 from "../number/number2024";
import numData2025 from "../number/number2025";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "moreHistory",
  data() {
    return {
      columns: [
        { text: "PC28", value: 11 },
        { text: "天子六合彩", value: 9999 },
      ],
    };
  },
  setup() {
    const lotteryType = ref("");
    const showlotteryTypePopup = ref(false);
    const lotteryTypeValue = ref([]);
    const workingDate = ref("");
    const showCalendar = ref(false);
    const selectBtm = ref(false);
    const gameHistoryList = ref([]);
    const gameNumList = ref([]);
    const showPC28Table = ref(false);
    const showTzTable = ref(false);
    const route = useRoute();

    onMounted(() => {
      lotteryTypeValue.value = [route.params.id];
      lotteryType.value = route.params.name;
      const todate = new Date();
      workingDate.value = formatDate(todate);
      submit();
    });

    const onLotteryTypeConfirm = ({ selectedValues, selectedOptions }) => {
      showlotteryTypePopup.value = false;
      lotteryTypeValue.value = selectedValues;
      lotteryType.value = selectedOptions[0].text;
      submit();
    };

    const back = () => {
      history.back();
    };

    const formatDate = (dateString) => {
      const newdate = new Date(dateString);
      const year = newdate.getFullYear();
      const month = String(newdate.getMonth() + 1).padStart(2, "0");
      const day = String(newdate.getDate()).padStart(2, "0");
      return `${year}${month}${day}`;
    };
    const onDateConfirm = (value) => {
      showCalendar.value = false;
      workingDate.value = formatDate(value);
      submit();
    };

    const submit = async () => {
      showPC28Table.value = false;
      showTzTable.value = false;
      if (lotteryTypeValue.value[0] && workingDate.value) {
        selectBtm.value = true;
        const params = {
          LotteryID: lotteryTypeValue.value[0],
          WorkingDate: workingDate.value,
        };
        const res = await getTodateLotteryDarw(params);
        selectBtm.value = false;
        if (res.length == 0) {
          showToast("暂无数据");
          return;
        }
        gameHistoryList.value = res;
        if (lotteryTypeValue.value[0] == 9999) {
          const arr = res.map((item) => {
            return formatNum(item.DrawResult, item.RoundNo, item.DrawTS);
          });
          gameNumList.value = arr;
          showTzTable.value = true;
          return;
        }
        showPC28Table.value = true;
      } else {
        showToast("请选择彩种或者日期");
      }
    };

    const formatPC28Num = (drawResult) => {
      if (drawResult) {
        return drawResult.split(",").map(Number);
      }
    };

    const numberSum = (drawResult) => {
      if (drawResult) {
        return drawResult
          .split(",")
          .map(Number)
          .reduce((acc, curr) => acc + curr, 0);
      }
    };

    const formatNum = (drawResult, roundNo, DrawTS) => {
      if (!drawResult) return;
      const arr = drawResult.split(",").map(Number);
      const targetDate = "2025-01-29";
      const now = new Date(DrawTS).toISOString().slice(0, 10);
      const numData = now < targetDate ? numData2024 : numData2025;
      const newArr = arr.map((item) => {
        const result = {
          roundNo: roundNo,
          number: item.toString().padStart(2, "0"),
          zodiac: null,
          fiveElements: null,
          color: null,
        };
        // 查找生肖
        for (const [key, values] of Object.entries(numData.zodiac)) {
          if (values.includes(item)) {
            result.zodiac = key;
            break;
          }
        }
        // 查找五行
        for (const [key, values] of Object.entries(numData.fiveElements)) {
          if (values.includes(item)) {
            result.fiveElements = key;
            break;
          }
        }
        // 查找波色
        for (const [key, values] of Object.entries(numData.color)) {
          if (values.includes(item)) {
            result.color = key;
            break;
          }
        }
        return result;
      });
      return newArr;
    };

    return {
      lotteryType,
      showlotteryTypePopup,
      lotteryTypeValue,
      onLotteryTypeConfirm,
      minDate: new Date(2023, 0, 1),
      workingDate,
      showCalendar,
      onDateConfirm,
      selectBtm,
      submit,
      formatPC28Num,
      numberSum,
      gameHistoryList,
      gameNumList,
      formatNum,
      showPC28Table,
      showTzTable,
      back,
    };
  },
};
</script>

<style lang="scss">
@mixin flex($justify: center, $alignItems: center, $direction: row) {
  display: flex;
  flex-direction: $direction;
  align-items: $alignItems;
  justify-content: $justify;
}

@mixin border($witdh: 1px, $color: #cadcfa) {
  border: $witdh solid $color;
}
.moreHistory-page {
  padding: 10px;
  .page-content {
    .select-box {
      margin-bottom: 10px;
    }
    .select-btm {
      margin-bottom: 10px;
      button {
        width: 100%;
        font-weight: 600;
      }
    }
  }

  .table-pc {
    .table-header {
      background-color: #e2e2e2;
      .row-header {
        text-align: center;
        .item-col {
          padding: 2px;
          border: 1px solid #eeeeee;
        }
      }
    }
    .table-content {
      overflow-y: scroll;
      height: 100vh;
      text-align: center;
      .row-item {
        .item-col {
          border: 1px solid #eeeeee;
          padding: 2px;
        }
        &:nth-child(2n) {
          background-color: #e2e2e2;
        }
        .item-col {
          span {
            margin: 0px 8px;
          }
        }
      }
    }
  }

  .table-tz {
    padding-bottom: 50px;
    @include flex(center, center, column);

    .row {
      width: 100%;
      @include border(1px, #eeeeee);
      border-radius: 4px;
      background-color: #e2e2e2;
      margin: 4px 0px;
      border: 1px solid #eeeeee;
    }

    .row-header {
      @include flex;
      justify-content: space-between;
      padding: 10px;
      .header-roundNo {
        display: flex;
        align-items: flex-end;
        p {
          margin: 0;
          padding: 0;
          color: #f14400;
        }
      }
      .header-dateTime {
        font-size: 10px;
        font-weight: 500;
        color: #f14400;
      }
    }

    .row-content {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      .item {
        .ball {
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
          width: 30px;
          height: 30px;
          margin-bottom: 8px;

          &-red {
            background-image: url("../assets/home/redBall.png");
          }
          &-bule {
            background-image: url("../assets/home/buleBall.png");
          }
          &-green {
            background-image: url("../assets/home/greenBall.png");
          }
          .ball-number {
            position: absolute;
            top: 4px;
            left: 6px;
            font-size: 14px;
            font-weight: 600;
          }
        }

        .ball-class {
          font-size: 12px;
          font-weight: 400;
          color: #727272;
          letter-spacing: 2px;
        }

        .icon-add {
          text-align: center;
          align-content: center;
          font-size: 18px;
          font-weight: 600;
        }
      }

      .item:nth-child(7) {
        order: 8;
      }
      .item:nth-child(8) {
        order: 7;
      }
    }

    .row-header {
      background-color: #e2e2e2;
      font-size: 12px;
      font-weight: 600;
      color: #000000;
      div {
        text-align: center;
        padding: 4px;
        border: 0.5px solid #eeeeee;
      }
    }

    .table-col {
      text-align: center;
      padding: 4px;
      border: 0.5px solid #eeeeee;
      font-size: 12px;
      font-weight: 500;
      color: #777777;
      span {
        margin: 0px 8px;
      }
    }
  }
}
</style>
